section .inner.status h1 {
    color: #000;
    font-size: 50px;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
}
section .status-container{
    color: #000;
    font-size: 18px;
    border-left: solid 20px #ccc;
    padding: 20px;
    margin-bottom: 20px;
    background: #e5e5e5;
}
section .status-container p{
    color: #000;
}
section .status-container.issue9{
    border-left: solid 20px #1a6d1a;
    background: #d5e5d0;
}
section .status-container.issue3{
    border-left: solid 20px #999999;
    background: #e5e5e5;
}
section .status-container.issue2{
    border-left: solid 20px #ffcc00;
    background: #fffae3;
}
section .status-container.issue1{
    border-left: solid 20px #f41818;
    background: #f1d6d6;
}
section .status-container h2{
    color: #000;
    font-size: 25px;
    margin-bottom: 10px;
}
section .status-container p{
    font-size: 18px;
    margin-bottom: 15px;
}



section .status-text{
    color: #000;
    font-size: 18px;
    padding: 20px 0;
    margin-bottom: 20px;
}
section .status-text h2{
    color: #000;
    font-size: 25px;
    margin-bottom: 10px;
}
section .status-text p{
    color: #000;
    font-size: 18px;
    margin-bottom: 15px;
}
section .status-text p label{
    display: inline-block;
    width: 100px;
}


section .status-form.hide{
    display: none;
}
section .status-form.show{
    display: block;
}