section .inner.news h1 {
    color: #000;
    font-size: 50px;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
}
section .news-container{
    color: #000;
    font-size: 18px;
    padding: 20px 0;
    margin-bottom: 20px;
    border-top: solid 1px #000;
}
section .news-container h2{
    color: #000;
    font-size: 25px;
    margin-bottom: 10px;
}
section .news-container p{
    font-size: 18px;
    margin-bottom: 15px;
    color: #000;
}