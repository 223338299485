$navitemheight: 7.692307692vh;      

*{
    margin: 0;
    padding: 0;
}
body{
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 12px;
    background: #ffffff;
    font-weight: 400;
    min-height: 100%;
    letter-spacing: 0.1em;
    width: 100%
}
b{
    font-weight: 700;
}
a, a:link, a:active, a:visited{
    color: #000000;
    text-decoration: none; 
    cursor: pointer;
}
a:hover{
    color: #000000;
    text-decoration: none; 
}
.mt{
    margin-top: 20px;
}
.mb{
    margin-bottom: 20px;
}
a.btn{
    padding: 10px 20px;
    background: #CC0000;
    color: #fff;
    text-transform: uppercase;
    font-size: 23px;
    display: inline-block;
}
a.btn.black{
    background: #000000;
}
@media(max-width:400px){
    a.btn{
        font-size: 20px;
    }
}
/*****************************************************************************/
