.icon-scroll,
.icon-scroll:before {
    position: absolute;
    left: 50%;
}
.icon-scroll {
    cursor: pointer;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    bottom: 20px;
    margin-top: -25px;
    box-shadow: inset 0 0 0 1px #333;
    border-radius: 25px;
}
.icon-scroll:before {
    content: '';
    width: 16px;
    height: 16px;
    background: #333;
    margin-left: -8px;
    top: 2px;
    border-radius: 8px;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
    animation-name: scroll;
}
@-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(35px);
        transform: translateY(35px);
    }
}
@keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(35px);
        transform: translateY(35px);
    }
}