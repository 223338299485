.dvgui-form-group {
  position: relative;
  margin: 0 0 20px 0;
  padding-top: 15px; }
  .dvgui-form-group .dvgui-text, .dvgui-form-group .trumbowyg-box {
    display: block;
    width: 100%;
    background: none;
    border: 0;
    outline: none;
    border-bottom: 2px solid #666666;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    color: #333333;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    font-size: 23px;
    font-weight: 700;
    line-height: 1.8; }
    .dvgui-form-group .dvgui-text::-moz-placeholder, .dvgui-form-group .trumbowyg-box::-moz-placeholder {
      color: #999999;
      opacity: 1; }
    .dvgui-form-group .dvgui-text:-ms-input-placeholder, .dvgui-form-group .trumbowyg-box:-ms-input-placeholder {
      color: #999999; }
    .dvgui-form-group .dvgui-text::-webkit-input-placeholder, .dvgui-form-group .trumbowyg-box::-webkit-input-placeholder {
      color: #999999; }
    .dvgui-form-group .dvgui-text ~ label, .dvgui-form-group .trumbowyg-box ~ label {
      font-size: 14px;
      top: 19px;
      transition: 0.2s ease all;
      color: #999999;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      pointer-events: none;
      transition: 0.2s ease all;
      line-height: 1.5;
      margin-bottom: 0; }
      .dvgui-form-group .dvgui-text ~ label:after, .dvgui-form-group .trumbowyg-box ~ label:after {
        content: '';
        position: absolute;
        z-index: 5;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 3px;
        width: 0;
        visibility: hidden;
        transition: 0.2s ease all; }
    .dvgui-form-group .dvgui-text ~ .help-block, .dvgui-form-group .trumbowyg-box ~ .help-block {
      color: #666666;
      display: none; }
      .dvgui-form-group .dvgui-text ~ .help-block:not(:empty), .dvgui-form-group .trumbowyg-box ~ .help-block:not(:empty) {
        opacity: 1 ;
        filter: alpha(opacity=100) ; }
    .dvgui-form-group .dvgui-text.edited:not([readonly]) ~ label, .dvgui-form-group .dvgui-text:focus:not([readonly]) ~ label, .dvgui-form-group .trumbowyg-box.edited:not([readonly]) ~ label, .dvgui-form-group .trumbowyg-box:focus:not([readonly]) ~ label {
      color: #4d4d4d;
      opacity: 1 ;
      filter: alpha(opacity=100) ; }
      .dvgui-form-group .dvgui-text.edited:not([readonly]) ~ label:after, .dvgui-form-group .dvgui-text:focus:not([readonly]) ~ label:after, .dvgui-form-group .trumbowyg-box.edited:not([readonly]) ~ label:after, .dvgui-form-group .trumbowyg-box:focus:not([readonly]) ~ label:after {
        visibility: visible;
        left: 0;
        width: 100%;
        background: #666666; }
    .dvgui-form-group .dvgui-text:focus:not([readonly]) ~ .help-block, .dvgui-form-group .trumbowyg-box:focus:not([readonly]) ~ .help-block {
      color: #666666;
      display: block;
      opacity: 1 ;
      filter: alpha(opacity=100) ; }
    .dvgui-form-group .dvgui-text[readonly] ~ label, .dvgui-form-group .dvgui-text.edited ~ label, .dvgui-form-group .dvgui-text:focus:not([readonly]) ~ label, .dvgui-form-group .dvgui-text.focus:not([readonly]) ~ label, .dvgui-form-group .trumbowyg-box[readonly] ~ label, .dvgui-form-group .trumbowyg-box.edited ~ label, .dvgui-form-group .trumbowyg-box:focus:not([readonly]) ~ label, .dvgui-form-group .trumbowyg-box.focus:not([readonly]) ~ label {
      top: 0;
      font-size: 13px; }
  .dvgui-form-group .trumbowyg-box {
    font-weight: 400;
    margin-top: 5px; }
  .dvgui-form-group .dvgui-select {
    display: block;
    width: 100%;
    background: none;
    border: 0;
    border-bottom: 1px solid #666666;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    color: #333333;
    box-shadow: none;
    padding: 2px 30px 2px 0;
    cursor: pointer;
    font-size: 23px;
    font-weight: 700;
    line-height: 1.8;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: -2px;
    outline: 0; }
    .dvgui-form-group .dvgui-select::-moz-placeholder {
      color: #999999;
      opacity: 1; }
    .dvgui-form-group .dvgui-select:-ms-input-placeholder {
      color: #999999; }
    .dvgui-form-group .dvgui-select::-webkit-input-placeholder {
      color: #999999; }
    .dvgui-form-group .dvgui-select ~ label {
      font-size: 14px;
      top: 19px;
      transition: 0.2s ease all;
      color: #999999;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      pointer-events: none;
      transition: 0.2s ease all;
      line-height: 1.5;
      margin-bottom: 0;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%230' fill-rule='evenodd' opacity='.54' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px bottom 12px; }
      .dvgui-form-group .dvgui-select ~ label:after {
        content: '';
        position: absolute;
        z-index: 5;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 2px;
        width: 0;
        visibility: hidden;
        transition: 0.2s ease all; }
    .dvgui-form-group .dvgui-select ~ .help-block {
      color: #666666;
      display: none; }
      .dvgui-form-group .dvgui-select ~ .help-block:not(:empty) {
        opacity: 1 ;
        filter: alpha(opacity=100) ; }
    .dvgui-form-group .dvgui-select.edited:not([readonly]) ~ label, .dvgui-form-group .dvgui-select:focus:not([readonly]) ~ label {
      color: #4d4d4d;
      opacity: 1 ;
      filter: alpha(opacity=100) ; }
      .dvgui-form-group .dvgui-select.edited:not([readonly]) ~ label:after, .dvgui-form-group .dvgui-select:focus:not([readonly]) ~ label:after {
        visibility: visible;
        left: 0;
        width: 100%;
        background: #666666; }
    .dvgui-form-group .dvgui-select:focus:not([readonly]) ~ .help-block {
      color: #666666;
      display: block;
      opacity: 1 ;
      filter: alpha(opacity=100) ; }
    .dvgui-form-group .dvgui-select[readonly] ~ label, .dvgui-form-group .dvgui-select.edited ~ label, .dvgui-form-group .dvgui-select:focus:not([readonly]) ~ label, .dvgui-form-group .dvgui-select.focus:not([readonly]) ~ label {
      top: 0;
      font-size: 13px; }
  .dvgui-form-group .help-block {
    position: absolute;
    margin: 2px 0 0 0;
    opacity: 0 ;
    filter: alpha(opacity=0) ;
    font-size: 13px; }
  .dvgui-form-group .dvgui-radio {
    position: relative;
    margin-top: 8px; }
    .dvgui-form-group .dvgui-radio.inline {
      display: inline-block;
      margin-right: 20px; }
    .dvgui-form-group .dvgui-radio ~ label {
      top: 0;
      font-size: 13px;
      color: #4d4d4d;
      width: 100%;
      position: absolute;
      left: 0; }
    .dvgui-form-group .dvgui-radio input[type=radio] {
      visibility: hidden;
      position: absolute; }
    .dvgui-form-group .dvgui-radio label {
      cursor: pointer;
      padding-left: 30px;
      margin-bottom: 0; }
    .dvgui-form-group .dvgui-radio label > span {
      display: block;
      position: absolute;
      left: 0;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      transition-duration: 0.3s; }
    .dvgui-form-group .dvgui-radio label > span.inc {
      background: #fff;
      left: -20px;
      top: -20px;
      height: 60px;
      width: 60px;
      opacity: 0;
      border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      -webkit-border-radius: 50% !important; }
    .dvgui-form-group .dvgui-radio label > .box {
      top: 0px;
      border: 1px solid #666666;
      height: 20px;
      width: 20px;
      border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      -webkit-border-radius: 50% !important;
      z-index: 5; }
    .dvgui-form-group .dvgui-radio label > .check {
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      background: #333333;
      opacity: 0;
      z-index: 6;
      border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      -webkit-border-radius: 50% !important;
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      transform: scale(0); }
    .dvgui-form-group .dvgui-radio label > span.inc {
      -webkit-animation: growCircle 0.3s ease;
      -moz-animation: growCircle 0.3s ease;
      animation: growCircle 0.3s ease; }
    .dvgui-form-group .dvgui-radio input[type=radio]:checked ~ label > .check {
      opacity: 1;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      transform: scale(1); }
    .dvgui-form-group .dvgui-radio input[type=radio]:disabled ~ label,
    .dvgui-form-group .dvgui-radio input[type=radio][disabled] ~ label {
      cursor: not-allowed;
      opacity: 0.5 ;
      filter: alpha(opacity=50) ; }
    .dvgui-form-group .dvgui-radio input[type=radio]:disabled ~ label > .box,
    .dvgui-form-group .dvgui-radio input[type=radio][disabled] ~ label > .box {
      cursor: not-allowed;
      opacity: 0.5 ;
      filter: alpha(opacity=50) ; }
    .dvgui-form-group .dvgui-radio input[type=radio]:disabled:checked ~ label > .check,
    .dvgui-form-group .dvgui-radio input[type=radio][disabled]:checked ~ label > .check {
      cursor: not-allowed;
      opacity: 0.5 ;
      filter: alpha(opacity=50) ; }
  .dvgui-form-group .dvgui-checkbox {
    position: relative; }
    .dvgui-form-group .dvgui-checkbox:first-child {
      margin-top: 8px; }
    .dvgui-form-group .dvgui-checkbox ~ label {
      top: 0;
      font-size: 13px;
      color: #4d4d4d;
      width: 100%;
      position: absolute;
      left: 0; }
    .dvgui-form-group .dvgui-checkbox.md-checkbox-inline {
      display: inline-block; }
    .form-inline .dvgui-form-group .dvgui-checkbox.md-checkbox-inline {
      margin-right: 20px;
      top: 3px; }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox] {
      visibility: hidden;
      position: absolute; }
    .dvgui-form-group .dvgui-checkbox label {
      cursor: pointer;
      padding-left: 30px; }
    .dvgui-form-group .dvgui-checkbox label > span {
      display: block;
      position: absolute;
      left: 0;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      transition-duration: 0.3s; }
    .dvgui-form-group .dvgui-checkbox label > span.inc {
      background: #fff;
      left: -20px;
      top: -20px;
      height: 60px;
      width: 60px;
      opacity: 0;
      border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      -webkit-border-radius: 50% !important; }
    .dvgui-form-group .dvgui-checkbox label > .box {
      top: 0px;
      border: 1px solid #666666;
      height: 20px;
      width: 20px;
      z-index: 5;
      -webkit-transition-delay: 0.2s;
      -moz-transition-delay: 0.2s;
      transition-delay: 0.2s; }
    .dvgui-form-group .dvgui-checkbox label > .check {
      top: -4px;
      left: 6px;
      width: 10px;
      height: 20px;
      border: 2px solid #333333;
      border-top: none;
      border-left: none;
      opacity: 0;
      z-index: 5;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition-delay: 0.3s;
      -moz-transition-delay: 0.3s;
      transition-delay: 0.3s; }
    .dvgui-form-group .dvgui-checkbox label > span.inc {
      -webkit-animation: growCircle 0.3s ease;
      -moz-animation: growCircle 0.3s ease;
      animation: growCircle 0.3s ease; }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox]:checked ~ label > .box {
      opacity: 0;
      -webkit-transform: scale(0) rotate(-180deg);
      -moz-transform: scale(0) rotate(-180deg);
      transform: scale(0) rotate(-180deg); }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox]:checked ~ label > .check {
      opacity: 1;
      -webkit-transform: scale(1) rotate(45deg);
      -moz-transform: scale(1) rotate(45deg);
      transform: scale(1) rotate(45deg); }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox]:disabled ~ label,
    .dvgui-form-group .dvgui-checkbox input[type=checkbox][disabled] ~ label {
      cursor: not-allowed;
      opacity: 0.5 ;
      filter: alpha(opacity=50) ; }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox]:disabled ~ label > .box,
    .dvgui-form-group .dvgui-checkbox input[type=checkbox][disabled] ~ label > .box {
      cursor: not-allowed;
      opacity: 0.5 ;
      filter: alpha(opacity=50) ; }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox]:disabled:checked ~ label > .check,
    .dvgui-form-group .dvgui-checkbox input[type=checkbox][disabled]:checked ~ label > .check {
      cursor: not-allowed;
      opacity: 0.5 ;
      filter: alpha(opacity=50) ; }
    .dvgui-form-group.has-error .dvgui-text.edited:not([readonly]) ~ label:after,
    .dvgui-form-group.has-error .dvgui-text.edited:not([readonly]) ~ .form-control-focus:after, .dvgui-form-group.has-error .dvgui-text.form-control-static ~ label:after,
    .dvgui-form-group.has-error .dvgui-text.form-control-static ~ .form-control-focus:after, .dvgui-form-group.has-error .dvgui-text:focus:not([readonly]) ~ label:after,
    .dvgui-form-group.has-error .dvgui-text:focus:not([readonly]) ~ .form-control-focus:after, .dvgui-form-group.has-error .dvgui-text.focus:not([readonly]) ~ label:after,
    .dvgui-form-group.has-error .dvgui-text.focus:not([readonly]) ~ .form-control-focus:after {
      background: #d10000; }
    .dvgui-form-group.has-error .dvgui-text.edited:not([readonly]) ~ label, .dvgui-form-group.has-error .dvgui-text.form-control-static ~ label, .dvgui-form-group.has-error .dvgui-text:focus:not([readonly]) ~ label, .dvgui-form-group.has-error .dvgui-text.focus:not([readonly]) ~ label {
      color: #d10000; }
    .dvgui-form-group.has-error .dvgui-text.edited:not([readonly]) ~ i, .dvgui-form-group.has-error .dvgui-text.form-control-static ~ i, .dvgui-form-group.has-error .dvgui-text:focus:not([readonly]) ~ i, .dvgui-form-group.has-error .dvgui-text.focus:not([readonly]) ~ i {
      color: #d10000; }
    .dvgui-form-group.has-error .dvgui-text.edited:not([readonly]) ~ .help-block, .dvgui-form-group.has-error .dvgui-text.form-control-static ~ .help-block, .dvgui-form-group.has-error .dvgui-text:focus:not([readonly]) ~ .help-block, .dvgui-form-group.has-error .dvgui-text.focus:not([readonly]) ~ .help-block {
      color: #d10000; }
  .dvgui-form-group.has-error .dvgui-select {
    border-bottom: 1px solid #d10000; }
    .dvgui-form-group.has-error .dvgui-select.edited:not([readonly]) ~ label:after,
    .dvgui-form-group.has-error .dvgui-select.edited:not([readonly]) ~ .form-control-focus:after, .dvgui-form-group.has-error .dvgui-select.form-control-static ~ label:after,
    .dvgui-form-group.has-error .dvgui-select.form-control-static ~ .form-control-focus:after, .dvgui-form-group.has-error .dvgui-select:focus:not([readonly]) ~ label:after,
    .dvgui-form-group.has-error .dvgui-select:focus:not([readonly]) ~ .form-control-focus:after, .dvgui-form-group.has-error .dvgui-select.focus:not([readonly]) ~ label:after,
    .dvgui-form-group.has-error .dvgui-select.focus:not([readonly]) ~ .form-control-focus:after {
      background: #d10000; }
    .dvgui-form-group.has-error .dvgui-select.edited:not([readonly]) ~ label, .dvgui-form-group.has-error .dvgui-select.form-control-static ~ label, .dvgui-form-group.has-error .dvgui-select:focus:not([readonly]) ~ label, .dvgui-form-group.has-error .dvgui-select.focus:not([readonly]) ~ label {
      color: #d10000; }
    .dvgui-form-group.has-error .dvgui-select.edited:not([readonly]) ~ i, .dvgui-form-group.has-error .dvgui-select.form-control-static ~ i, .dvgui-form-group.has-error .dvgui-select:focus:not([readonly]) ~ i, .dvgui-form-group.has-error .dvgui-select.focus:not([readonly]) ~ i {
      color: #d10000; }
    .dvgui-form-group.has-error .dvgui-select.edited:not([readonly]) ~ .help-block, .dvgui-form-group.has-error .dvgui-select.form-control-static ~ .help-block, .dvgui-form-group.has-error .dvgui-select:focus:not([readonly]) ~ .help-block, .dvgui-form-group.has-error .dvgui-select.focus:not([readonly]) ~ .help-block {
      color: #d10000; }
  .dvgui-form-group.has-error label {
    color: #d10000; }

  