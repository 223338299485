section .inner.training h1 {
    color: #000;
    font-size: 50px;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
}
section .inner.training h2 {
    color: #000;
    font-size: 25px;
    margin-bottom: 10px;
}
section .training-container{
    color: #000;
    font-size: 18px;
    border-left: solid 20px #ccc;
    padding: 20px;
    margin-bottom: 20px;
    background: #e5e5e5;
    display: flex;
}
section .training-container .icon{
    width: 18px;
    height: 18px;
    margin-right: 10px;
}
section .training-container i{
    flex: 200px 0 0;
}
section .training-container label{
    width: 110px;
    display: inline-block;
}
section .training-container.future{
    border-left: solid 20px #1a6d1a;
    background: #d5e5d0;
}
section .training-container.past{
    border-left: solid 20px #999999;
    background: #e5e5e5;
    b {
        flex-grow:1;
    }
    div {
        min-width: 280px;
        text-align: right;
    }
}
section .training-container.issue{
    border-left: solid 20px #f41818;
    background: #f1d6d6;
}
section .training-container h2{
    color: #000;
    font-size: 25px;
    margin-bottom: 10px;
}
.toggle_container {
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    border-radius: 10px;
    margin-bottom: 10px;
    .toggle_header {
        display:flex;
        cursor: pointer;
        padding: 10px;
        h2 {
            flex-grow: 1;
            margin-top: 10px;
        }
        svg {
            rotate: 90deg;
            height: 35px;
            width: 35px;
            margin-top: 10px;
        }
    }
    .toggle_content{
        display: none;
        padding: 0 10px 10px;
    }
}
@media(max-width:600px){
    section .training-container{
        display: block;
    }
}
