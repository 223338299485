section#clips{
    margin: 0;
    overflow: hidden;

    .inner{
        overflow: hidden;
        margin: 0;
    }
}
.clip-stage{
    //border: solid 1px #ccc; // temp
    height: 90vh;
    height: calc(90vh - 2px);
    width: 90vw;
    width: calc(90vw - 2px);
    margin: 5vh 5vw;
    position: relative;
    /*overflow: hidden;*/
    
    .clip-step {
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;
        
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: none;
        
        p {
            font-size: 10vw;
            width: 90vw;
            width: calc(100% - 2px);
            color: #000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 10;
        }
        img {
            height: 100%;
            max-width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 8;
        }
        .stage-img {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 8;
            background-position: center;
            background-size: cover;
        }

        &.current{
            display: block;
            
            &.grow{
                animation: clip-grow10 1s;
                
                &.size2{
                    p{
                        animation: clip-grow2 1s;
                    }
                }
                &.size4{
                    p{
                        animation: clip-grow4 1s;
                    }
                }
                &.size5{
                    p{
                        animation: clip-grow5 1s;
                    }
                }
                &.size7{
                    p{
                        animation: clip-grow7 1s;
                    }
                }
                &.size10{
                    p{
                        animation: clip-grow10 1s;
                    }
                }
                animation-timing-function: ease-in-out;
                opacity: 1;
            }
            &.fade{
                animation: clip-fade 0.5s;
                animation-timing-function: ease-in;
                opacity: 1;
            }
            &.fade2{
                animation: clip-fade 2.5s;
                animation-timing-function: ease-in-out;
                opacity: 1;
            }
            &.switch{
                opacity: 1;
            }
        }
        &.bold{
            font-weight: 700;
        }
        &.size2{
            p{
                font-size: 2vw;
            }
        }
        &.size4{
            p{
                font-size: 4vw;
            }
        }
        &.size5{
            p{
                font-size: 5vw;
            }
        }
        &.size7{
            p{
                font-size: 7vw;
            }
        }
        &.size10{
            p{
                font-size: 10vw;
            }
        }
        &.c1_1{
            p{
                color: #17bce6;
                font-weight: 700;
                right: 2vw;
                width: auto;
                transform: none;
                top: 14vh;
            }
        }
        &.c1_2{
            p{
                color: #0291de;
                font-weight: 700;
                transform: translateX(-50%);
                top: 18vh;
                font-size: 6vw;
            }
            .stage-img{
                background-position: top;
            }
        }
        &.c1_3{
            p{
                color: #e1570f;
                font-weight: 700;
                transform: translateX(-50%);
                top: auto;
                bottom: 13vh;
                font-size: 12vw;
            }
        }
        &.c1_4{
            p{
                color: #c3e3b9;
                opacity: 0.5;
                font-weight: 700;
                right: 2vw;
                transform: translateX(-50%);
                top: auto;
                font-size: 8vw;
                bottom: 16vh;
            }
        }
        &.c1_5{
            p{
                color: #ea1532;
                font-weight: 700;
                right: 2vw;
                transform: translateX(-50%);
                bottom: 3vh;
                font-size: 9vw;
                top: auto;
            }
        }
        &.smr-container{
            font-size: 1px;
            position: relative;
            
            .smr{
                position: absolute;
                top: 25vh;
                height: 20vh;
                opacity: 1;
            }
            p{
                width: 70%;
                color: #000;
                margin: auto;
                font-size: 3vh;
                position: absolute;
                top: 55vh;
            }
            .btn,.lnk{
                position: absolute;
                bottom: 9vh;
                left: 50%;
                transform: translateX(-50%);
                font-size: 3vh;
            }
        }
        &.c2_1{
            p{
                color: #0291de;
                font-weight: 700;
                top: 14vh;
                left: 6vh;
                font-size: 7vw;
                transform: none;
                text-align: left;
            }
            .stage-img{
                background-position: right;
            }
        }
        &.c2_2{
            p{
                color: #dd4d5f;;
                font-weight: 700;
                width: auto;
                transform: none;
                left: 2vw;
                top: 2vh;
                text-align: center;
                width: 100%;
            }
        }
        &.c2_3{
            p{
                color: #e14126;
                font-weight: 700;
                right: 2vw;
                width: auto;
                transform: none;
                top: 2vh;
                text-align: left;
            }
            .stage-img{
                background-position: 20%;
            }
        }
        &.c2_4{
            p{
                color: #f8869f;
                font-weight: 700;
                transform: none;
                top: 9vh;
                width: 40%;
                left: auto;
                font-size: 10vh;
                right: 2vw;
                text-align: right;            
            }
        }
        &.c2_5{
            p{
                color: #5239ac;
                font-weight: 700;
                right: 2vw;
                transform: translateX(-50%);
                bottom: 3vh;
                font-size: 9vw;
                top: auto;
            }
        }
   
    }
    &.home{
        border:0;
        height: 100vh;
        width: 100vw;
        width: calc(100vw - #{$navitemheight});
        margin: 0;
        
        .clip-step {
            &.smr-container{            
                p{
                    top: 50vh;
                }
                .btn{
                    bottom: calc(70px + 3vh);
                    font-size: 3vh;
                }
            }
        }
        .prev, .pause, .play, .next{
            bottom: 10px;
        }

    }
    
    .prev, .pause, .play, .next, .loop{
        z-index: 999;
        cursor: pointer;
        position: absolute;
        bottom: -30px;
        left: 10px;
        
        svg{
            width: 20px;
            height: 20px;
            fill: #ccc;
        }
    }
    .pause{
        left: 35px;
    }
    .play{
        left: 55px;
    }
    .next{
        left: 80px;
    }
    .loop{
        left: 120px;
        &.on{
            svg{
                fill: #666;
            }
        }
    }
    .source{
        position: absolute;
        bottom: 20px;
        right: 20px;
        font-size: 14px;
        color: #ccc;
        z-index: 10;
        
        a, a:visited, a:link, a:hover{
            color: #ccc;
        }
    }
}

@media (max-aspect-ratio: 1.4/1) {
    .clip-stage{

        .clip-step {
            &.current{
                &.c2_1{
                    p{
                        top: 2vh;
                        font-size: 6vw;
                    }
                }
                &.c2_4{
                    p{
                        top: 3vh;
                        width: 100%;
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
        &.home{
            .clip-step {
                &.c2_1{
                    p{
                        bottom: 8vh;
                        font-size: 6vw;
                        top: auto;
                        left: 9vw;   
                    }
                }
                &.c2_2{
                    p{
                        font-size: 6vw;
                        top: 10vh;
                    }
                }
                &.c2_4{
                    p{
                        color: #2478bf;
                        top: auto;
                        bottom: 5px;
                    }
                }
            }
        }
    }
}


@media (max-aspect-ratio: 1/1) {
    .clip-stage{

        .clip-step {
            &.current{
                &.c1_1{
                    p{
                        top: 49vh;
                    }
                }
                &.c1_2{
                    p{
                        top: 30vh;
                    }
                }
                &.c1_3{
                    p{
                        bottom: 28vh;
                    }
                }
                &.c1_4{
                    p{
                        bottom: 27vh;
                    }
                }
                &.c1_5{
                    p{
                        bottom: 10vh;
                    }
                }
                &.c2_1{
                    p{
                        font-size: 10vw;
                    }
                    .stage-img{
                        background-position: 80%;
                    }
                }
                &.c2_4{
                    p{
                        font-size: 12vw;
                    }
                }
            }
            &.smr-container{            
                .smr{
                    top: 15vh;
                    height: 15vh;
                }
                p{
                    width: 80%;
                    font-size: 4vw;
                    top: 43vh;
                }
                .btn,.lnk{
                    font-size: 3vw;
                    width: 80%;
                }
            }
        }
        &.home{
            .clip-step {
                &.smr-container{            
                    p{
                        width: 80%;
                        font-size: 4vw;
                        top: 43vh;
                    }
                    .btn{
                        font-size: 4vw;
                    }
                }
                &.c2_3{
                    p{
                        top: 10vh;
                    }
                }
            }
        }
    }  
}


@media (max-aspect-ratio: 1/2) {
    .clip-stage{

        .clip-step {
            &.current{
                &.c1_1{
                    p{
                        top: 49vh;
                    }
                }
                &.c1_2{
                    p{
                        top: 30vh;
                    }
                }
                &.c1_3{
                    p{
                        bottom: 40vh;
                    }
                }
                &.c1_4{
                    p{
                        bottom: 35vh;
                    }
                }
                &.c1_5{
                    p{
                        bottom: 30vh;
                    }
                }
                &.c2_4{
                    p{
                        font-size: 10vw;
                    }
                }
            }
            &.smr-container{            
                p{
                    width: 80%;
                    font-size: 5vw;
                    top: 40vh;
                }
                .btn,.lnk{
                    font-size: 5vw;
                }
            }
        }
        &.home{
            .clip-step {
                &.smr-container{            
                    p{
                        width: 80%;
                        font-size: 5vw;
                        top: 40vh;
                    }
                    .btn{
                        font-size: 5vw;
                    }
                }
            }
        }
    }
}













@keyframes clip-grow2 {
    from { 
        opacity: 0; 
        width: 70%;
        font-size: 1vw;
    }
    to   { 
        opacity: 1; 
        width: 100%;
        font-size: 2vw;
    }
}
@keyframes clip-grow4 {
    from { 
        opacity: 0; 
        width: 80%;
        font-size: 3vw;
    }
    to   { 
        opacity: 1; 
        width: 100%;
        font-size: 4vw;
    }
}
@keyframes clip-grow5 {
    from { 
        opacity: 0; 
        width: 90%;
        font-size: 4vw;
    }
    to   { 
        opacity: 1; 
        idth: 100%;
        font-size: 5vw;
    }
}
@keyframes clip-grow7{
    from { 
        opacity: 0; 
        width: 90%;
        font-size: 6vw;
    }
    to   { 
        opacity: 1; 
        width: 100%;
        font-size: 7vw;
    }
}
@keyframes clip-grow10 {
    from { 
        opacity: 0; 
        width: 90%;
        font-size: 9vw;
    }
    to   { 
        opacity: 1; 
        width: 100%;
        font-size: 10vw;
    }
}
@keyframes clip-fade {
    from { 
        opacity: 0; 
    }
    to   { 
        opacity: 1; 
    }
}