section#datenschutz {
    padding-top: 150px;
    
    .inner{
        max-width: 800px;
        margin: auto;
        font-size: 18px;
        
        h1{
            color: #000;
            font-size: 45px;
            text-transform: uppercase;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        h2{
            color: #000;
            font-size: 40px;
            margin-bottom: 30px;
        }
        h3{
            color: #000;
            font-size: 18px;
            margin-bottom: 15px;
        }
        p{
            font-size: 18px;
            margin-bottom: 15px;
            color: #000;
        }
        
        
        .icons{
            display: flex;
            
            label {
                flex: 130px 0 0;
                margin: 20px 20px 0 0;
                font-size: 14px;
            }
            
            div{
                flex-grow: 2;
                font-size: 12px;
                display: flex;
                flex-wrap: wrap;
                
                span{
                    b{
                        display: block;
                    }
                    display: block;
                    float: left;
                    flex: 150px 0 0;
                    margin: 0 30px 30px 0;
                    padding: 15px;
                    
                    &.wedonot{
                        background: #ededed;
                    }
                }
            }
            i{
                font-size: 100px;
                display: block;
            }
        }
    }
}
@media(max-width:900px){
    section#datenschutz {
        padding-top: 100px;

        .inner{
            width: 100%;
            width: calc(100% - 100px);
            margin: 50px;
            
            h1{
                color: #000;
                font-size: 25px;
                text-transform: uppercase;
                margin-top: 25px;
                margin-bottom: 25px;
            }
            h2{
                color: #000;
                font-size: 20px;
                margin-bottom: 25px;
            }
            h3{
                color: #000;
                font-size: 16px;
                margin-bottom: 15px;
            }
            p{
                font-size: 16px;
                margin-bottom: 15px;
                color: #000;
            }
        }
    }
}
@media(max-width:550px){
    section#datenschutz {
        padding-top: 100px;

        .inner{
            width: 100%;
            width: calc(100% - 40px);
            margin: 20px;
            
            h1{
                color: #000;
                font-size: 25px;
                text-transform: uppercase;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            h2{
                color: #000;
                font-size: 18px;
                margin-bottom: 20px;
            }
            h3{
                color: #000;
                font-size: 14px;
                margin-bottom: 12px;
            }
            p{
                font-size: 14px;
                margin-bottom: 12px;
                color: #000;
            }
        }
    }
}