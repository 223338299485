#scrollto-kontakt{
    min-height: calc(100vh - 200px);
}
.engagement-container {
    margin-bottom: 60px;
}
.engagement-container img {
    width: 40%;
    margin-right: 9%;
}

.upcount-container{
    display: flex;
    flex-wrap: wrap;
    
    .upcount{
        flex: 33.333% 0 0;
        margin-bottom: 50px;
        
        span{
            font-size: 60px;
            display: block;
            text-align: center;
            color: #fff;
            font-weight: 700;
            height: 70px;
        }
        p {
            display: block;
            text-align: center;
            height: 30px;
        }
        small{
            display: block;
            text-align: center;
            font-size: 12px;
            color: #fff;
        }
    }
}
.clientmap{
    width: 100%;
    margin-bottom: 50px;
}
@media(max-width:600px){
    .engagement-container {
        text-align: center;
    }
    .engagement-container img {
        width: 75%;
        margin: 0;
    }
}
@media(max-width:450px){
    .engagement-container img {
        width: 100%;
    }
}

.partner-container {
    margin-bottom: 60px;
    margin-top: 30px;
    
    img {
        width: 15%;
        margin-right: 1%;
        margin-bottom: 1%;
    }
}
@media(max-width:1200px){
    .partner-container {
        img {
            width: 18%;
        }
    }
}
@media(max-width:900px){
    .partner-container {
        img {
            width: 23%;
        }
    }
}
@media(max-width:700px){
    .partner-container {
        img {
            width: 31%;
        }
    }
}
@media(max-width:550px){
    .partner-container {
        img {
            width: 48%;
        }
    }
}
@media(max-width:550px){
    .partner-container {
        img {
            width: 100%;
            margin-right: 0;
        }
    }
}
.messagebox .success{
    background: #73eb73;
    padding: 20px;
    font-size: 23px;
    border-left: solid 5px #1a6d1a;
}
.messagebox .error{
    background: #ff9999;
    padding: 20px;
    font-size: 23px;
    border-left: solid 5px #f41818;
}