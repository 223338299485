nav{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 98;
}
nav a{
    display: block;
    width: 50px;
    height: 50px;
    width: $navitemheight;
    height: $navitemheight;
    cursor: pointer;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;        
    display: block;
    
    span{
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none; 
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;        
        display: block;
        position: absolute;
        width: 0.001px;
        color: transparent;
        transition: width 0.5s ease-in-out, color 0.7s; 
    }
    &:hover,
    &:active{
        span{
            right: $navitemheight;
            top: 0;
            padding: 20px;
            height: 50px;
            width: 320px;
            height: calc(#{$navitemheight} - 40px);
            background: #ccc;
            font-size: 35px;
            color: #fff;
            font-weight: 700;
            z-index: 999;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none; 
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;        
        }
    }
}

@media (hover: none),(pointer: coarse) {
    nav a{
        transition: none;
        
        &:hover,
        &:active{
            span{
                right: -500px;
                width: 0.001px;
                height: 0.001px;
                padding: 0;
                color: transparent;
                font-size: 0.001px;
            }
        }
        span{
            right: -500px;
            width: 0.001px;
            height: 0.001px;
            padding: 0;
            font-size: 0.001px;
            color: transparent;
            transition: none;
        }
    }
}

@media(max-width:440px){
    nav a{
        transition: none;

        &:hover,
        &:active{
            span{
                width: calc(100vw - #{$navitemheight}-40px);
                padding: 0;
                color: transparent;
                height: 0;
            }
        }
    }
}

#smr-s, #contact{
    width: 30px;
    height: 30px;
    width: calc(#{$navitemheight} - 2vh);
    height: calc(#{$navitemheight} - 2vh);
    margin: 1vh;
}
.nav-icon{
    svg{
        width: 30px;
        height: 30px;
        width: calc(#{$navitemheight} - 4vh);
        height: calc(#{$navitemheight} - 4vh);
        margin: 2vh;
        fill: #fff;
    }
}