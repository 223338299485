footer {
    display: flex;
    margin-right: calc(#{$navitemheight} + 20px);
    align-items: flex-end;
    height: 200px;
    
    p {
        font-size: 23px;
    }

    .address {
        margin: 20px 30px 40px 30px;
        flex-grow: 2;
    }
    .label{
        margin: 20px 0 40px 20px;
        
        img{
            height: 150px;
        }
    }
}
@media(max-width:700px){
    footer{
        flex-wrap: wrap;
        
        .label{
            width: 100%;
        }
    }
}
@media(max-width:400px){
    footer p{
        font-size: 16px;
    }
}
