header{
    position: relative;
}
#smr{
    width: 5vw;
    position: absolute;
    top: 1vw;
    opacity: 0;
    left: 2vw;
    transition: all 0.4s ease-in;
    z-index: 99;
    
    &.in-viewport{
        left: 2vw;
        width: 15vw;
        opacity: 1;
    }
}
@media(max-width:1000px){
    #smr{
        width: 10vw;
        
        &.in-viewport{
            width: 20vw;
            opacity: 1;
        }
    }
}
@media(max-width:750px){
    #smr{
        width: 20vw;
        
        &.in-viewport{
            width: 30vw;
            opacity: 1;
        }
    }
}
@media(max-width:400px){
    #smr{
        width: 30vw;
        
        &.in-viewport{
            width: 40vw;
            opacity: 1;
        }
    }
}
