
section{
    min-height: 100vh;
    overflow: hidden;
}
section .inner{
    margin: 20px 30px 60px 30px;
    margin-right: calc(#{$navitemheight} + 20px);
}
#status,
#training{
    .inner{
        margin-right: 30px;
    }
}
section {
    .col1{
        max-width: 850px;
        margin: auto;
        
        .galery{
            display: none;
            
            img {
                width: 750px;
                margin-bottom: 20px;
            }
            &.init{
                margin-top: 30px;
                display: inherit;
            }
        }
    }
    .title-container{
        margin-bottom: 30px;
        
        h2 {
            color:#fff;
            font-size: 50px;
            text-transform: uppercase;
            margin-left: -100%;
            transition: 0.5s ease margin-left;
        }
        
        &.in-viewport{
            h2{
                margin-left: 0;
            }
        }
    }
    h3{
        color:#fff;
        font-size: 30px;
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .text-container{
        opacity: 0;
        
        &.in-viewport{
            overflow: hidden;
            
            p{
                animation: main-fadein 2s;
                animation: main-moveup 1s
            }
            opacity: 1;
        }
    }
    p{
        font-size: 23px;
        color: #fff;
        margin-bottom: 20px;

        img.cont-img-s,
        img.cont-img-m,
        img.cont-img-l{
            max-width: 100%;
        }
    }
    .inner{        
        &.start,
        &.contact{
            margin-top: 120px;
            margin-top: calc(10vh + 140px);
            color: #000;

            h1{
                font-size: 70px;
                text-transform: uppercase;
                color: #000;
            }
            h2{
                color: #000;
            }
            p{
                color: #000;
            }
        }
        &.contact{
            margin-top: 30px;
        }
    }
}
.title-icon{
    width: 45px;
    height: 45px;
    fill: #fff;
    margin-right: 20px;
}

@keyframes main-moveup {
    from { 
        margin-top: 300px;
    }
    to   { 
        margin-top: 0;
    }
}
@keyframes main-fadein {
    from { 
        opacity: 0; 
    }
    to   { 
        opacity: 1; 
    }
}

@media(max-width:850px){
    section .inner{
        flex-wrap: wrap;
    }
    section .col1{
        width: 100%;
    }
    section .col1 .galery img{
        width: 100%;
    }
    section .col1 .galery{
        display: block;
    }
    .team .img{
        width: 25vw;
        height: 33vw;
    }
}

@media(max-width:700px){
    section .title-container h2.mob-smaller{
        font-size: 35px;
    }
}
@media(max-width:550px){
    section .title-container h2.mob-smaller{
        font-size: 28px;
    }
}
@media(max-width:450px){
    section .title-container h2.mob-smaller{
        font-size: 20px;
    }
}
@media(max-width:400px){
    section .title-container h2{
        font-size: 25px;
    }
    section p{
        font-size: 16px;
    }
    section .inner{
        margin: 20px;
        margin-right: calc(#{$navitemheight} + 20px);
    }
    section .inner.start h1{
        font-size: 35px;
    }
    section .inner.start p{
        font-size: 16px;
    }
    .title-icon{
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}